"use client";
import { useState, useEffect } from "react";
// import { Button } from "antd";
import useChatNow from "app/models/hooks/useChatNow";
import { addEventTagForH5 } from "helpers/utilities";
import { useSearchParams } from "next/navigation";
import styles from "./Chat.pc.module.scss";
import { Button } from "../../Button/pc/index.pc";
import classNames from "classnames";

const Chat = (props) => {
  const searchParams = useSearchParams();
  const { jobId, data, sessionid, variant = "primaryBtn", className = "", chatText } = props;
  const { chat_button } = data || {};
  const source = searchParams.get("source");
  const chat = {
    chat_id: chat_button?.chat_id,
    is_exists: chat_button?.is_exists,
    job_application_id: chat_button?.job_application_id,
    job_id: chat_button?.job_id,
    job_identity_restrictions: data?.job_description?.identity_restrictions
  };
  const jobDetail = {
    chat,
    id: jobId,
    job_identity_restrictions: data?.job_description?.identity_restrictions,
    is_resume_required: data?.is_resume_required
  };
  const isLogin = sessionid ? true : false;
  const [loading, chatNow, changeJobModal, userData] = useChatNow(
    jobDetail,
    source ?? "job_details"
  );
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    document.addEventListener("scroll", handleAddHeadBoxShadow);
    return () => document.removeEventListener("scroll", handleAddHeadBoxShadow);
  }, []);

  useEffect(() => {
    if (isLogin) {
      setDisabled(!userData?.id);
    } else {
      setDisabled(false);
    }
  }, [isLogin, userData]);

  const handleAddHeadBoxShadow = () => {
    const scrollTopHeight = document.body.scrollTop || document.documentElement.scrollTop;
    const headNode = document.querySelector("#jobDetaiPagelHead");
    if (!headNode) return;
    if (scrollTopHeight > 55) {
      (headNode as HTMLElement).style.boxShadow = "10px 5px 5px rgba(217,217,217, 0.6)";
    } else {
      (headNode as HTMLElement).style.boxShadow = "unset";
    }
  };
  const handleBtnEvent = () => {
    if (!disabled) {
      (chatNow as any)?.(jobDetail);
      if (isLogin) {
        addEventTagForH5("sgh5_jobdetail_success_chat");
      } else {
        addEventTagForH5("sgh5_job_detail_chat");
        // sessionStorage.setItem('click_source_from', 'chat')
      }
    }
  };

  const handleGetChatNowElement = () => {
    const text = chat_button?.is_exists ? chat_button?.continue_text : chat_button?.text;

    return (
      <div className={styles.chatButton}>
        <Button
          disabled={disabled}
          loading={loading as boolean}
          onClick={handleBtnEvent}
          variant={variant}
          btnSize='size40'
          className={classNames(styles.button, className)}
        >
          {chatText ? chatText : text}
        </Button>
      </div>
    );
  };
  if (chat_button) {
    return (
      <div>
        {handleGetChatNowElement()}
        {changeJobModal}
      </div>
    );
  }

  return null;
};

export default Chat;
