import React, { forwardRef } from "react";

import Benefits from "../Benefits/Benefits.pc";
import Desc from "../Desc/Desc.pc";
import RemoteDetail from "../RemoteDetail/RemoteDetail.pc";
import JobHeader from "../JobHeader/JobHeader.pc";
import Location from "../Location/Location.pc";
import Recruiter from "../Recruiter/Recruiter.pc";
import styles from "./JobDetail.pc.module.scss";
import { useJobTrans } from "../jobTransProvider";
import classNames from "classnames";
import { getLang } from "helpers/country";

function JobDetail({ countryKey, data, sessionid, jobId, type = "default" }, ref) {
  const { transData } = useJobTrans();
  const langKey = getLang();

  // console.log("MainSection@data", jobId, data);

  const handleRedirect = () => {
    if (type === "web3") {
      window.open(`/${langKey}` + data.job_header?.job_url + "?source=web3", "_blank");
      return;
    }

    window.open(`/${langKey}` + data.job_header?.job_url, "_blank");
  };

  return (
    <>
      <div className={classNames([styles.mainSection, "override-main-section"])} ref={ref}>
        <div className={classNames([styles.mainInner, "job-main-inner"])}>
          <JobHeader
            transData={transData}
            jobId={jobId}
            sessionid={sessionid}
            data={{
              ...data.remote_job_detail,
              ...data.job_header,
              allData: data,
              translationTitle: data.job_translation?.translation_content?.job_title
            }}
          />
          <RemoteDetail data={data.remote_job_detail} />
          <Desc
            transData={transData}
            countryKey={countryKey}
            data={{
              ...data.job_description,
              extraInfo: data.japan_info,
              translationSkills: data.job_translation?.translation_content?.job_skills,
              translationDescription:
                data.job_translation?.translation_content?.job_description_html,
              translationRequirements:
                data.job_translation?.translation_content?.job_requirements_html,
              benefits_info: data.benefits_info
            }}
          />
          <Recruiter data={data || {}} sessionid={sessionid} jobId={jobId} />
          <Location data={data.working_location} />
          {/* <Benefits data={data.benefits_info} /> */}
          <div className={styles.viewMore}>
            <span className={styles.viewMoreText} onClick={handleRedirect}>
              {data?.more_text}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='19'
                height='18'
                viewBox='0 0 19 18'
                fill='none'
              >
                <path
                  d='M5.75 12.75L13.25 5.25M13.25 5.25H5.75M13.25 5.25V12.75'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            </span>
          </div>
        </div>
        {/* <p className={styles.pubDate}>{data.published_at}</p> */}
      </div>
    </>
  );
}

export default forwardRef(JobDetail);
