import flagInter from 'images/flags/flag_inter.png'
import flagUS from 'images/flags/US.png'
import flagGB from 'images/flags/GB.png'
import flagAE from 'images/flags/AE.png'
import flagBR from 'images/flags/BR.png'
import flagCA from 'images/flags/CA.png'
import flagHK from 'images/flags/HK.png'
import flagID from 'images/flags/ID.png'
import flagJP from 'images/flags/JP.png'
import flagLK from 'images/flags/LK.png'
import flagMO from 'images/flags/MO.png'
import flagMY from 'images/flags/MY.png'
import flagNG from 'images/flags/NG.png'
import flagPH from 'images/flags/PH.png'
import flagSG from 'images/flags/SG.png'
import flagTR from 'images/flags/TR.png'
import flagTW from 'images/flags/TW.png'
import flagES from 'images/flags/ES.png'
import flagRS from 'images/flags/RS.png'

const flagImages = {
  com: flagInter,
  us: flagUS,
  gb: flagGB,
  ae: flagAE,
  br: flagBR,
  ca: flagCA,
  hk: flagHK,
  id: flagID,
  jp: flagJP,
  lk: flagLK,
  mo: flagMO,
  my: flagMY,
  ng: flagNG,
  ph: flagPH,
  sg: flagSG,
  tr: flagTR,
  tw: flagTW,
  es: flagES,
  rs: flagRS
}

export const getFlagByKey = (key: string) => flagImages[key.toLowerCase()]?.src || ''