import React from "react";
import Skills from "./Skills.pc";
import Translation from "../Translation/Translation.pc";
import styles from "./Desc.pc.module.scss";
import Benefits from "../Benefits/Benefits.pc";

export default function Desc({ data, countryKey, transData }) {
  console.log("benefits_info", data);

  return (
    <div className={styles.descContentWrap}>
      {data.identity_restrictions?.length ? (
        <p className={styles.identity}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='24' height='24' rx='6' fill='#3CEAF6' />
            <path
              d='M11.1426 9.66682C11.1426 10.9121 10.1332 11.9215 8.88793 11.9215C7.64269 11.9215 6.63322 10.9121 6.63322 9.66682C6.63322 8.42158 7.64269 7.41211 8.88793 7.41211H11.1426V9.66682Z'
              fill='black'
            />
            <path
              d='M12.9733 16.587C13.0393 16.587 13.0935 16.5345 13.0935 16.4685C13.0935 14.1458 11.2106 12.2629 8.88793 12.2629C6.56526 12.2629 4.68237 14.1458 4.68237 16.4685C4.68237 16.5345 4.73654 16.587 4.80252 16.587H12.9733Z'
              fill='black'
            />
            <path
              d='M17.366 8.47461L19.3176 8.47461'
              stroke='black'
              strokeWidth='2.1'
              strokeLinecap='round'
            />
            <path
              d='M17.366 12.459L19.3176 12.459'
              stroke='black'
              strokeWidth='2.1'
              strokeLinecap='round'
            />
          </svg>
          {data.identity_description}
        </p>
      ) : null}
      <div className={styles.descContent}>
        <div className={styles.descTitle}>
          <h3>{data.title}</h3>
          <Translation />
        </div>

        <Benefits data={data.benefits_info} />

        {data.description_html ? (
          <div className={styles.richText}>
            <h4>{data.description_title}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: transData.isOriginal ? data.description_html : data.translationDescription
              }}
            />
          </div>
        ) : null}
        {data.requirement_html ? (
          <div className={styles.richText}>
            <h4>{data.requirement_title}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: transData.isOriginal ? data.requirement_html : data.translationRequirements
              }}
            />
          </div>
        ) : null}

        {Object.keys(data?.extraInfo ?? {}).some((el) => data?.extraInfo?.[el]) ? (
          <div className={styles.extraInfo}>
            {data?.extraInfo?.work_time ? (
              <div className={styles.row}>
                <span className={styles.label}>{data?.extraInfo?.work_time?.key}</span>
                <p>{data?.extraInfo?.work_time?.work_time_text}</p>
              </div>
            ) : null}
            {data?.extraInfo?.holiday ? (
              <div className={styles.row}>
                <span className={styles.label}>{data?.extraInfo?.holiday?.key}</span>
                <p>{data?.extraInfo?.holiday?.value_text}</p>
              </div>
            ) : null}
            {data?.extraInfo?.insurance ? (
              <div className={styles.row}>
                <span className={styles.label}>{data?.extraInfo?.insurance?.key}</span>
                <p>{data?.extraInfo?.insurance?.value_text}</p>
              </div>
            ) : null}
            {data?.extraInfo?.contract ? (
              <div className={styles.row}>
                <span className={styles.label}>{data?.extraInfo?.contract?.key}</span>
                <p>{data?.extraInfo?.contract?.value}</p>
              </div>
            ) : null}
            {data?.extraInfo?.smoking ? (
              <div className={styles.row}>
                <span className={styles.label}>{data?.extraInfo?.smoking?.key}</span>
                <p>{data?.extraInfo?.smoking?.value}</p>
              </div>
            ) : null}
          </div>
        ) : null}

        <Skills data={transData.isOriginal ? data.skills : data.translationSkills} />
      </div>
    </div>
  );
}
