import configuredAxios from 'helpers/configuredAxios'
import queryString from 'query-string'

const fetchCompanySuggestionsService = (payload, headers) => {
  const axios = configuredAxios('company')
  if (headers) {
    return axios.get(`/search-suggestion?${queryString.stringify(payload)}`, { headers })
  }
  return axios.get(`/search-suggestion?${queryString.stringify(payload)}`)
}

const fetchCompanySuggestionsServiceForJp = (payload, headers) => {
  const axios = configuredAxios('company')
  if (headers) {
    return axios.get(`/combined_search_suggestion?${queryString.stringify(payload)}`, { headers })
  }
  return axios.get(`/combined_search_suggestion?${queryString.stringify(payload)}`)
}

export { fetchCompanySuggestionsService, fetchCompanySuggestionsServiceForJp }
